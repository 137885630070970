<template>
  <v-app>
  <!-- <v-app style="background: linear-gradient(150deg, #e9c874 15%, #c9a15c 70%, #926f34 94%);"> -->
    <v-main>
      <router-view />
      <!-- Back to Top Button -->
      <div @click="scrollToTop()" :class="['back-to-top-btn', { 'go-top': isTop }]">
        <v-icon>keyboard_arrow_up</v-icon>
      </div>
      <!-- Back to Top Button vége -->
    </v-main>
    <v-footer class="d-flex flex-column" color="#8b6c57">
      <div class="text-center white--text">
        <strong>Shining Body</strong> | Győr |
        <strong>{{ new Date().getFullYear() }}</strong>
      </div>
      <!-- <div class="text-center">
        {{ new Date().getFullYear() }}
      </div> -->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    isTop: false,
    drawer: false,
    group: null,
  }),
  methods: {
    scrollToTop() {
      window.scroll({ behavior: "smooth", left: 0, top: 0 });
    },
  },
  mounted() {
    document.title = "Shining Body Alakformáló Stúdió";
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 120) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.back-to-top-btn {
  position: fixed;
  cursor: pointer;
  right: 40px;
  color: #ffffff;
  // background-color: #dfa110;
  background-color: #c2a38e;
  width: 40px;
  height: 40px;
  visibility: hidden;
  border-radius: 100%;
  transition: 0.6s;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

  i {
    position: absolute;
    right: 0;
    left: 0;
    top: 45%; //ez állítja az ikon pozícióját
    transform: translateY(-45%);
    font-size: 22px; //ez állítja az ikon méretét
    margin-left: auto;
    margin-right: auto;
  }
  &.go-top {
    opacity: 1;
    visibility: visible;
    bottom: 70px;
  }
  &:hover {
    opacity: 1;
    background-color: #c2a38e;
    color: #ffffff;
    transition: 0.6s;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transform: translateY(-5px);
  }
}
</style>
