<template>
  <v-container class="black--text" ref="top">
    <!-- MENÜ -->

    <v-row>
      <v-col class="d-flex justify-space-around px-auto">
        <!-- <v-col class="px-auto text-center"> -->
        <!-- <v-sheet>
          <v-btn plain text color="#8b6c57" to="/">Főoldal</v-btn>
          <v-btn plain text color="#8b6c57" @click="hazirend">Házirend</v-btn>
          <v-btn plain text color="#8b6c57" @click="kezelesekClick">Kezelések</v-btn>
          <v-btn plain text color="#8b6c57" @click="arlistaClick">Árlista</v-btn>
          <v-btn plain text color="#8b6c57" @click="elerhetosegekClick">Elérhetőségek</v-btn>
          </v-sheet> -->

        <header class="header">
          <input
            v-model="menuclick"
            @click="menuclick = !menuclick"
            class="menu-btn"
            type="checkbox"
            id="menu-btn"
          />
          <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
          <ul class="menu">
            <li><v-btn plain text @click="top">Főoldal</v-btn></li>
            <li><v-btn plain text @click="hazirend">Házirend</v-btn></li>
            <li><v-btn plain text @click="kezelesekClick">Kezelések</v-btn></li>
            <li><v-btn plain text @click="arlistaClick">Árlista</v-btn></li>
            <li><v-btn plain text @click="elerhetosegekClick">Elérhetőségek</v-btn></li>
          </ul>
        </header>
      </v-col>
      <!-- <v-col :cols="12" :lg="12" :md="4" :sm="12" class="d-flex justify-end"></v-col> -->
    </v-row>

    <v-row class="d-flex justify-center text-center">
      <v-col cols="12" class="hover1">
        <figure><img :src="require('@/assets/logo.png')" contain height="250" /></figure>
      </v-col>
      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/logo.png')" contain height="250" />
      </v-col> -->
      <v-col>
        <h3 class="font-weight-bold text-uppercase">
          Ragyogj Te is a Shining Body Alakformáló Stúdió segítségével!
        </h3>
        <div class="d-flex justify-center">
          <v-sheet max-width="800" class="black--text text-center mt-2" color="white">
            <p class="text-caption font-weight-regular">
              Maximális szakmai tapasztalat, professzionális esztétikai kezelések és
              gépek. Ingyenes állapotfelmérés és bőrdiagnosztika. Itt minden problémádra
              megtalálod a megoldást!<br /><br />
              A fő szempontom mindig is az volt, hogy egy kellemes, barátságos hangulatot
              teremtsek vendégeimnek, hogy szeressék a közös munkát, és élvezzék az itt
              töltött időt. <br />Mert mindenki megérdemli, hogy
              <span class="font-weight-bold text-uppercase">Ragyogjon!</span>
            </p>
          </v-sheet>
        </div>
        <!-- KÉPEK -->
        <v-sheet
          class="mx-auto align-self-center"
          min-height="500"
          max-height="600"
          max-width="400"
          width="350"
        >
          <v-carousel cycle interval="3500" hide-delimiters :show-arrows="false">
            <v-carousel-item src="@/assets/szalon/2.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/3.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/4.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/5.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/6.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/7.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/8.jpeg" cover></v-carousel-item>
          </v-carousel>
        </v-sheet>
        <!-- KÉPEK -->
        <br />
        <v-sheet
          max-width="25%"
          class="mx-auto text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- HÁZIREND -->
    <v-row class="text-center" background-color="#000fff">
      <v-col cols="12" ref="hazirend">
        <h2 class="mx-auto font-weight-bold mt-2 text-uppercase">Házirend</h2>
      </v-col>
      <v-col class="mb-4">
        <v-sheet
          max-width="70%"
          class="mx-auto text-caption black--text text-center mb-8"
          color="white"
        >
          <h3 class="font-weight-bold mb-3 text-uppercase">Érkezés</h3>
          <p class="text-caption font-weight-regular">
            A foglalt időpont az edzés/kezelés kezdetét jelenti! Kérlek Titeket, hogy 10
            perccel előbb érkezzetek! Ha késel, a saját edzésed/kezelésed idejéből veszed
            el, hiszen a következő pontosan érkező vendégemet nem várathatom meg!
          </p>

          <h3 class="font-weight-bold mb-3 text-uppercase">Lemondás</h3>
          <p class="text-caption font-weight-regular">
            Minimum 24 órával előtte le kell mondani az edzést/kezelést! Más különben az
            adott alkalom elveszik! Az aznapi időpont másnapra rakása is időpont
            lemondásnak számít!
          </p>

          <h3 class="font-weight-bold mb-3 text-uppercase">Időpont</h3>
          <p class="text-caption font-weight-regular">
            Kérlek Titeket, hogy előre írjuk be az időpontokat pár hétre. Szeretném, ha
            mindenki a maga rendszere szerint tudna jönni. Időpontokat egyeztetés nélkül
            nem írok be!
          </p>

          <h3 class="font-weight-bold mb-3 text-uppercase">Bérlet</h3>
          <p class="text-caption font-weight-regular">
            A vásárolt bérletek nem átruházhatóak és nem becserélhetőek másik
            szolgáltatásra!
          </p>
        </v-sheet>
        <v-sheet
          max-width="20%"
          class="mx-auto text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- KEZELÉSEK -->
    <v-row class="text-center">
      <v-col cols="12" ref="kezelesek">
        <h2 class="mx-auto font-weight-bold text-uppercase">Kezelések</h2>

        <v-col></v-col>
        <v-sheet class="mx-auto text-caption black--text text-center pb-10" color="white">
          <h3 class="text-uppercase">Testkezelések</h3>
          <div class="mb-6">
            (Izomépítés, fogyás, feszesítés, narancsbőr és stria eltávolítás,
            alakformálás, szétnyílt hasizom kezelése.)
          </div>
          <!-- Ez a RF felugró -->
          <div class="text-center pb-2">
            <span
              color="#8b6c57"
              @click="sheet = !sheet"
              class="gomb text-uppercase font-weight-bold"
            >
              Rádiófrekvenciás kezelés
            </span>
            <v-bottom-sheet v-model="sheet" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet = !sheet">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-3">
                    <h4 class="pb-4">Rádiófrekvenciás kezelés</h4>
                    <p justify="center" class="text-caption">
                      Bipoláris rádiófrekvenciás zsírbontás (RF) kiegészül infralámpával
                      és pulzáló vákuum masszázzsal. A rádiófrekvenciás kezelés és a
                      fényterápia (a stimuláló vörös fény) kombinációjával kiemelkedő
                      eredményeket érünk el. Az elektromágneses energiával átjárhatóvá
                      tesszük a célzott területket sejtek anyagcseréje felfokozódik és
                      elindulnak a méreganyagok ürülése is a nyirokrendszeren keresztül.
                      Aktiváljuk a kollagén termelődését és az elasztikus rostok
                      összehúzódásával javítjuk a bőrfeszességet.<br /><strong
                        >Narancsbőr csökkentésére és bőrfeszesítésre.</strong
                      ><br />
                    </p>
                    <v-img
                      :src="require('@/assets/szolgaltatasok/radiofrekvencias.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a dekoltazs felugró -->
          <div class="text-center pb-2">
            <span
              color="#8b6c57"
              @click="sheet21 = !sheet21"
              class="gomb text-uppercase font-weight-bold"
            >
              Dekoltázs feszesítés
            </span>
            <v-bottom-sheet v-model="sheet21" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet21 = !sheet21">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-3">
                    <h4 class="pb-4">Dekoltázs feszesítés</h4>
                    <p justify="center" class="text-caption">
                      A bőr tartásáért a kötőszövetek felelősek, így az erősítésüknek nagy
                      a jelentősége a rugalmatlanságát és feszességét elvesztett bőr
                      helyreállításában valamint a ráncok elleni küzdelemben. A célzott
                      rádióhullámokkal a keletkező hő által kedvező folyamatokat indulnak
                      be a bőr mélyebb rétegeiben – a bőr természetes kollagén termelése
                      fokozható. A kollagén újratermelődése által a bőr öregedési
                      folyamata késleltethető, halványulnak a ráncok, laza petyhüdt bőr
                      feszesebbé válik.
                    </p>
                    <!-- <v-img
                      :src="require('@/assets/szolgaltatasok/radiofrekvencias.jpg')"
                      contain
                      height="250"
                    /> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a vákumos felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet16 = !sheet16"
              class="gomb text-uppercase font-weight-bold"
            >
              Vákumos kezelés
            </span>
            <v-bottom-sheet v-model="sheet16" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet16 = !sheet16">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-6">Vákumos kezelés</h4>
                    <p justify="center" class="text-caption">
                      Amellett, hogy a kezelés magában foglalja a rádiófrekvencia minden
                      előnyét, hiszen a vákuumos kezelőfejben beleépítve megtalálható, a
                      vákuummal végzett eljárás elősegíti a narancsbőr csökkenését,
                      természetesen zsírbontó hatása mellett. Joggal tarthatunk a vákuumos
                      kezelés után a bőr bekékülésétől, ez az eljárás azonban teljesen
                      kíméletesen lazítja fel a bőr letapadt szöveteit, így nincs ok az
                      aggodalomra. Egyéb zsírbontó eljárások kiváló kiegészítője lehet
                      mindazoknak, akik fogyni vágynak és narancsbőr problémájuk is van.
                    </p>
                    <v-img
                      :src="require('@/assets/szolgaltatasok/radiofrekvencias.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a kavitációs felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet2 = !sheet2"
              class="gomb text-uppercase font-weight-bold"
            >
              Kavitációs testkezelés
            </span>
            <v-bottom-sheet v-model="sheet2" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet2 = !sheet2">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-6">Kavitációs testkezelés</h4>
                    <p justify="center" class="text-caption">
                      Kavitációs zsírbontással egy kezelés során egy testtájék 3-6 percen
                      keresztül kezelhető. A kezelési időt illetve a kezelések közötti
                      napok számát a zsírszövet vastagsága határozza meg. A kezelések
                      végére a zsírpárnák a felére csökkennek, a bőr feszessé és simává, a
                      test kontúr jól formálttá válik.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/kavitacios.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a lipolézeres felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet3 = !sheet3"
              class="gomb text-uppercase font-weight-bold"
            >
              4D Lipolézeres zsírbontás
            </span>
            <v-bottom-sheet v-model="sheet3" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet3 = !sheet3">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-6">4D Lipolézeres zsírbontás</h4>
                    <p class="text-caption font-weight-regular">
                      A 4D műtétmentes lézeres zsírleszívás technológiának köszönhetően a
                      készülék egy időben négy különböző mélységben kezeli a zsírszövetet,
                      így látványosabb és gyorsabb eredményt lehet elérni különböző
                      testalkatú vendégeknél.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/lipolezeres.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az HIEMT+RF felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet6 = !sheet6"
              class="gomb text-uppercase font-weight-bold"
            >
              HIEMT+RF
            </span>
            <v-bottom-sheet v-model="sheet6" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet6 = !sheet6">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-6">HIEMT+RF</h4>
                    <p justify="center" class="text-caption">
                      Az EliteScupt a legerősebb HIEMT gép. Ez a 4 fejes verzió megadja
                      neked a HIEMT gépek maximumát, amivel 30 perces kezelés során két
                      testterületet is kezelhetünk egyszerre! Ráadásul beépített
                      rádiófrekvenciával is rendelkezik ami emeli a hatékonyságot! A HIEMT
                      elektromágneses impulzusai egymás után többezerszer 100%-os
                      összehúzódásra késztetik az izomrostokat, szemben a normál edzés
                      során tudatosan kifejtett izomösszehúzódások 20-30%-os
                      intenzitásával. 30 perc alatt 20.000 izomösszehúzást generál,
                      ezáltal a kezelt terület izomtömege megnő, egyúttal csökken a
                      zsírvastagság az érintett részen.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/hiemt.jpeg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h3 class="text-uppercase pt-8">Elektrokozmetikai arckezelések</h3>
          <!-- <h1 class="mt-8">Elektrokozmetikai kezelések</h1> -->
          <div class="mb-6">
            (Ráncok eltávolítása, problémás bőr kezelése, arc feltöltése orvosi tisztaságú
            hatóanyagokkal.)
          </div>
          <!-- Ez az enzimes felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet7 = !sheet7"
              class="gomb text-uppercase font-weight-bold"
            >
              Enzimes elektroporációs arckezelés
            </span>
            <v-bottom-sheet v-model="sheet7" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet7 = !sheet7">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Enzimes elektroporációs arckezelés</h4>
                    <p justify="center" class="text-caption">
                      Az Elektroporáció olyan bőrfiatalító és bőrmegújító eljárás, mely
                      serkenti a kollagén- és az elasztikus rostok szintézisét, így
                      jelentős mértékben lassítja a bőröregeddést, új megoldás az orvosi
                      tisztaságú hatóanyagok bőrbe juttatására.<br />
                      Egyszerűen, fájdalommentesen és hatékonyan. <br /><br />
                      <span class="font-weight-bold">A kezelés folyamata:</span><br />
                      1. Tisztítás<br />
                      2. Mikrodermabrázió<br />
                      3. Hidrodermabrázió<br />
                      4. Enzimes elektroporációs kezelés<br />
                      5. Befejező krém
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az enzimes oxigén felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet5 = !sheet5"
              class="gomb text-uppercase font-weight-bold"
            >
              Enzimes oxigénterápiás arckezelés
            </span>
            <v-bottom-sheet v-model="sheet5" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet5 = !sheet5">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Enzimes oxigénterápiás arckezelés</h4>
                    <p justify="center" class="text-caption">
                      Nagyhatású oxigén- és hatóanyag infúzió a fáradt, fakó bőrnek. Már
                      az első oxigén kezelés különösen látványos, megfiatalítja az
                      arcbőrt!<br />
                      A koncentrált oxigén a legfontosabb életelixír! Ez a forradalmi
                      arckezelés MINDEN bőrtípusra alkalmazható. A bőrbe oxigént, növényi
                      eredetű őssejteket, peptideket, nagy koncentrációjú enimes növényi
                      anyagokat visz.<br />
                      A bőr ragyogó, friss és fiatalos lesz!<br /><br />
                      <span class="font-weight-bold">A kezelés folyamata:</span><br />
                      1. Tisztítás <br />2. Mikrodermabrázió<br />
                      3. Hatóanyagos oxigén infúzió: tű nélkül, a magasnyomású oxigén
                      segítségével juttatjuk be az aktív szérumokat<br />
                      4. Bejefező krém
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az rádiófrekis felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet8 = !sheet8"
              class="gomb text-uppercase font-weight-bold"
            >
              Rádiófrekvenciás kezelés
            </span>
            <v-bottom-sheet v-model="sheet8" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet8 = !sheet8">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Rádiófrekvenciás kezelés</h4>
                    <p justify="center" class="text-caption">
                      A rádiófrekvnciás arckezelés során a hő hatására beindul a kollagén
                      szintézis, a keringés jabvul, így az arcbőr feszesebbé, erősebbé és
                      tömörebbé válik.<br />
                      Egy kezeléssorozat eredményeként halványulnak a ráncok, csökken a
                      pórusméret, javul a bőr állaga. A kollagén újratermelődésének
                      köszönhetően pedig késleltetjük az öregedési folyamatokat.<br /><br />
                      <span class="font-weight-bold">A kezelés folyamata:</span><br />
                      1. Tisztítás<br />
                      2. Mikrodermabrázió<br />
                      3. Arcfeszesítés rádiófrekvenciával<br />
                      4. Hideg-meleg terápiás vasalás<br />
                      5. Befejező krém
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az relax felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet9 = !sheet9"
              class="gomb text-uppercase font-weight-bold"
            >
              Relax kezelés
            </span>
            <v-bottom-sheet v-model="sheet9" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet9 = !sheet9">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Relax kezelés</h4>
                    <p justify="center" class="text-caption">
                      Kényeztesd magad egy tápláló és vitaminokban gazdag arckezeléssel. A
                      kellemes masszázs segít megteremteni a test és a lélek egyensúlyát,
                      miközben mélyen ápolja és hidratálja az arcbőrt.<br /><br />
                      <span class="font-weight-bold">A kezelés folyamata:</span><br />
                      1. Tisztítás<br />
                      2. Hidrodermabrázió<br />
                      3. Arc-, nyak-, dekoltázsmassázs<br />
                      4. Vitaminos krém
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az mélytisztító felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet10 = !sheet10"
              class="gomb text-uppercase font-weight-bold"
            >
              Mélytisztító vákumos kezelés
            </span>
            <v-bottom-sheet v-model="sheet10" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet10 = !sheet10">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Mélytisztító vákumos kezelés</h4>
                    <p justify="center" class="text-caption">
                      Fájdalommentesen távolítja el a faggyút a pórusokból. Az erőteljes,
                      vákuummal kombinált kezelőfej nem csupán hámlaszt, de egy időben
                      mikrodermabráziót és mély rétegű bőrtisztítást is végez.<br />
                      A fej spriál alakban juttatja a tisztító szalicilsavas folyadékot a
                      bőrre, hogy feloldja a szennyeződéseket, az elhalt hámsejteket, a
                      faggyúr és a komedót, az 5 fokozatban állítható vákuum pedig
                      tökéletesen eltávolít minden felesleget. Az eredmény tökéletesen
                      tiszta, ragyogó fiatalos arcbőr.<br /><br />
                      <span class="font-weight-bold">A kezelés folyamata:</span><br />
                      1. Tisztítás<br />
                      2. Hidrodermabrázió<br />
                      3. Vákumos mélytisztítás: mélytisztítás nyomkodás nélkül, a vákum
                      erejével<br />
                      4. Befejező szérum
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h3 class="text-uppercase pt-8">Masszázs</h3>
          <!-- <h1 class="mt-8">Masszázs</h1> -->
          <div class="mb-4"></div>
          <!-- Ez az cellulit felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet11 = !sheet11"
              class="gomb text-uppercase font-weight-bold"
            >
              Cellulit mélyfogyasztó masszázs
            </span>
            <v-bottom-sheet v-model="sheet11" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet11 = !sheet11">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Cellulit mélyfogyasztó masszázs</h4>
                    <p justify="center" class="text-caption">
                      A cellulit masszázs a zsírszöveteket meglazítja, megkönnyítve ezzel
                      a lebontásukat a szervezet számára. Hatására a kapillárisok
                      (hajszálerek) aktivizálódnak, vérbőség keletkezik, a bőr
                      hőmérséklete emelkedik, ezáltal olyan kémiai anyagok termelődnek,
                      melyeknek értágító hatásuk van, emiatt élénkül a vérkeringés. Ez a
                      szövetek jobb táplálásához vezet, a sejtek anyagcseréje is
                      felgyorsul.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Nyomásterápiás hullámmasszázs felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet12 = !sheet12"
              class="gomb text-uppercase font-weight-bold"
            >
              Nyomásterápiás hullámmasszázs
            </span>
            <v-bottom-sheet v-model="sheet12" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet12 = !sheet12">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Nyomásterápiás hullámmasszázs</h4>
                    <p justify="center" class="text-caption">
                      A páciens lábára, karjára, derekára speciális kompressziós ruházatot
                      adunk, a ruházat légkamráiban pedig váltakozó erősségű
                      nyomáshullámot generálunk, ezáltal a megfelelő irányba stimuláljuk a
                      nyirokhálózatot, így tisztítva a nyirokereket. A hullámmasszázs
                      során a légkamrák hol megtelnek, hol pedig kiáramlik belőlük a
                      levegő, így létrejön egy úgynevezett szorítóhullám. A nyirokrendszer
                      pangó részeinél segít megszabadulni a felesleges nyirok folyadéktól,
                      zsírtól, amelyek a nyirokcsomók segítségével természetes módon
                      ürülnek ki a szervezetből. A lábfejtől a szív irányába történik a
                      hullámmasszázs kezelés, kifejezetten jó érzést keltve a páciensben.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/nyomasterapia.jpeg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Nyomásterápiás hullámmasszázs + testtekercs felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet22 = !sheet22"
              class="gomb text-uppercase font-weight-bold"
            >
              Nyomásterápiás hullámmasszázs + testtekercselés
            </span>
            <v-bottom-sheet v-model="sheet22" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet22 = !sheet22">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Nyomásterápiás hullámmasszázs + testtekercselés</h4>
                    <p justify="center" class="text-caption">
                      Karcsúsítás, narancsbőr eltüntetés, stria halványítás és kötőszöveti
                      regenerálás.Aromaterápiás testtekercselés négyszeres hatékonysággal,
                      egy teljesen természetes és rendkívül hatékony testkezelési rendszer
                      arra, hogy jó néhány centimétert veszíts tested körvonalából, onnan
                      fogyj le, ahonnan szeretnél, s egyúttal megszépüljön a bőröd. A
                      Hullámmasszázs nyirokkeringés serkentésével gondoskodik az
                      alakformálás során felszabadított salakanyagok és lebontott zsírok
                      kiürüléséről a szervezetből.
                    </p>
                    <!-- <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/nyomasterapia.jpeg')"
                      contain
                      height="250"
                    /> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Izomlazító relaxáló masszázs felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet13 = !sheet13"
              class="gomb text-uppercase font-weight-bold"
            >
              Izomlazító relaxáló masszázs
            </span>
            <v-bottom-sheet v-model="sheet13" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet13 = !sheet13">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Izomlazító relaxáló masszázs</h4>
                    <p justify="center" class="text-caption">
                      A test kötött, feszes izmainak oldása, az izmok felszabadítása,
                      lazítás, ízületek kimozgatása, fájó pontok oldása. Nagyon jó
                      regeneráló, fájdalomcsillapító és méregtelenítő hatású masszázs. A
                      masszázs a gyógyító érintés tudománya. Egyike a legősibb
                      fizioterápiás gyógymódoknak. Olyan tevékenység, amelyet fizikai
                      fájdalmak esetén ösztönösen is alkalmazunk. A masszázs az érintésen
                      keresztül teremti meg a testi-lelki egyensúlyt. Az izomlazító
                      masszázs a svédmasszázs fogásrendszerével dolgozó masszázs, illetve
                      annak egy jóval erőteljesebb, intenzívebb, a mélyizomzatra kiható
                      változata. Ez a masszázs a feszülő, letapadt, merev, görcsös
                      izmokat, myalgiás csomókat felszabadítsa, ezáltal azok
                      rugalmasabbak, ellenállóbbak, terhelhetőbbek lesznek.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Vákumos izomlazítás/zsírtörés felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet14 = !sheet14"
              class="gomb text-uppercase font-weight-bold"
            >
              Vákumos izomlazítás/zsírtörés
            </span>
            <v-bottom-sheet v-model="sheet14" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet14 = !sheet14">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Vákumos izomlazítás/zsírtörés</h4>
                    <p justify="center" class="text-caption">
                      A vákuummasszázs a kézi nyirokmasszázs egy speciális formája. Az
                      eljárás egyesíti magában a hagyományos köpölyözést és a
                      nyirokmasszázst. Vérkeringést, nyirokkeringést javító, és
                      immunrendszert aktiváló hatását többféle probléma esetén is
                      felhasználhatjuk. Ez a sajátos technika felfokozza a nyirokáramlást,
                      és a vérkeringést. A nyirokkeringés akár ötvenszeresére is
                      felgyorsulhat, ami biztosítja a salakanyagok elszállítását a
                      szervezetből. A vákuum vérbőségkeltő hatása az anyagcsere
                      élénküléséhez és a salakanyagok jobb lebontásához vezet. A
                      salakanyagok tehát a legtermészetesebb úton távoznak a szervezetből,
                      sok folyadék segítségével. A kezelés hatására felgyorsul a
                      vérkeringés is, ezáltal javul a bőr alatti kötőszövetek
                      oxigénellátása és anyagcseréje. A felgyorsult keringés következtében
                      erősödik a szervezet zsírégetései folyamata. A vákuum feszítő hatása
                      révén a zsírsejtek jelentős részének burka áteresztővé válik, így a
                      zsír kifolyik belőlük és a nyirokkeringésen keresztül vesén át
                      távozik.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h3 class="text-uppercase pt-8">Kardió edzés</h3>
          <div class="mb-6"></div>
          <!-- Ez az Infratrainer felugró -->
          <div class="text-center pb-2">
            <span
              color="#dfa110"
              @click="sheet4 = !sheet4"
              class="gomb text-uppercase font-weight-bold"
            >
              Infratrainer
            </span>
            <v-bottom-sheet v-model="sheet4" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn class="mt-6" color="error" @click="sheet4 = !sheet4">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h4 class="pb-4">Infratrainer</h4>
                    <p justify="center" class="text-caption">
                      Az INFRATRAINER alapjaiban változtatja meg a fogyás, alakformálás,
                      narancsbőrkezelés, és méregtelenítés koncepcióját. Minden eddiginél
                      gyorsabb, hatékonyabb megoldást kínál felhasználóinknak. Az
                      INFRATRAINER hatékony testmozgást biztosít, függetlenül a jelenlegi
                      edzettségi szinttől és észrevehető változásokat eredményez már
                      néhány héten belül! Az edzés során az infravörös sugárzás
                      felmelegíti és feloldja a zsírszöveteket belülről, és felszabadítja
                      a méreganyagokat. A súlycsökkenés 20% -a zsír, amely kiemelkedő más
                      edzési módszerekhez képest, mint például az intenzív futás. A
                      súlycsökkenés mellett a méregtelenítő hatás csak rendkívül
                      megterhelő sportokkal hasonlítható össze, mint például a maratoni
                      futás.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/infratrainer.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->
        </v-sheet>
        <v-sheet
          max-width="20%"
          class="mx-auto mt-5 text-caption black--text text-center"
          color="white"
        >
          <v-divider color="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- ÁRLISTA -->
    <v-row class="text-center">
      <v-col ref="arlista">
        <h2 class="mx-auto text-uppercase mt-2 mb-6">Árlista</h2>
        <v-sheet
          class="mx-auto"
          min-height="600"
          max-height="750"
          width="400"
          max-width="400"
        >
          <v-carousel
            height="640"
            show-arrows-on-hover
            hide-delimiter-background
            class="mt-10"
            cycle
            interval="7500"
          >
            <v-carousel-item
              src="@/assets/arlista/uj/1.png"
              cover
              @click="bigimg(1)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/2.png"
              cover
              @click="bigimg(2)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/3.png"
              cover
              @click="bigimg(3)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/4.png"
              cover
              @click="bigimg(4)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/5.png"
              cover
              @click="bigimg(5)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/6.png"
              cover
              @click="bigimg(6)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/uj/7.png"
              cover
              @click="bigimg(7)"
            ></v-carousel-item>
          </v-carousel>
        </v-sheet>
        <div class="text-center">
          <v-dialog v-model="dialog" width="650">
            <v-img
              src="@/assets/arlista/uj/1_big.png"
              v-if="selectedimg == 1"
              contain
              max-height="920"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/2_big.png"
              v-if="selectedimg == 2"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/3_big.png"
              v-if="selectedimg == 3"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/4_big.png"
              v-if="selectedimg == 4"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/5_big.png"
              v-if="selectedimg == 5"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/6_big.png"
              v-if="selectedimg == 6"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/uj/7_big.png"
              v-if="selectedimg == 7"
              contain
              max-height="820"
            ></v-img>
          </v-dialog>
        </div>

        <v-col class="mb-4"
          ><v-sheet
            max-width="25%"
            class="mx-auto mt-16 text-caption black--text text-center"
            color="white"
          >
            <v-divider class="black"></v-divider>
          </v-sheet>
        </v-col>
      </v-col>
    </v-row>

    <!-- ELÉRHETŐSÉGEK -->
    <v-row class="text-center">
      <v-col cols="12" ref="elerhetosegek"
        ><h2 class="mx-auto mb-3 text-uppercase text-indigo-darken-2">
          <span class="text-indigo-darken-2">Elérhetőségek</span>
        </h2>
      </v-col>
    </v-row>
    <h3 class="text-center pt-6">SOCIAL MEDIA</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div>
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.facebook.com/shining.body.5/">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-facebook-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-facebook-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.instagram.com/shiningbody_gyor/">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-instagram-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-instagram-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.tiktok.com/@shiningbody_gyor">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-tiktok-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-tiktok-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <h3 class="text-center pt-6">KAPCSOLAT</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div class="white--text">
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="mailto:info@shiningbody.hu">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-email-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-email-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://goo.gl/maps/wpegcwfoUwcG2HQi9">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-location-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-location-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a href="tel:+36303179672">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-phone-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-phone-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <h3 class="text-center pt-6">NYITVATARTÁS</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div class="black--text">
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <strong>H-P</strong
              ><span class="pl-3" style="color: green">09:00-20:00</span>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <strong>SZ-V</strong><span class="pl-3" style="color: red">Zárva</span>
            </v-col>
          </v-row>
        </div>
        <v-sheet
          max-width="25%"
          class="mx-auto mt-16 text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    menuclick: false,
    sheet: false,
    sheet2: false,
    sheet3: false,
    sheet4: false,
    sheet5: false,
    sheet6: false,
    sheet7: false,
    sheet8: false,
    sheet9: false,
    sheet10: false,
    sheet11: false,
    sheet12: false,
    sheet13: false,
    sheet14: false,
    sheet15: false,
    sheet16: false,
    sheet20: false,
    sheet21: false,
    sheet22: false,
    dialog: false,
    selectedimg: 0,
  }),
  methods: {
    bigimg(id) {
      this.dialog = true;
      this.selectedimg = id;
    },
    top() {
      this.$refs.top.scrollIntoView({ behavior: "smooth" });
      this.menuclick = !this.menuclick;
    },
    hazirend() {
      this.$refs.hazirend.scrollIntoView({ behavior: "smooth" });
      this.menuclick = !this.menuclick;
    },
    kezelesekClick() {
      this.$refs.kezelesek.scrollIntoView({ behavior: "smooth" });
      this.menuclick = !this.menuclick;
    },
    arlistaClick() {
      this.$refs.arlista.scrollIntoView({ behavior: "smooth" });
      this.menuclick = !this.menuclick;
    },
    elerhetosegekClick() {
      this.$refs.elerhetosegek.scrollIntoView({ behavior: "smooth" });
      this.menuclick = !this.menuclick;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact:hover {
  color: blue;
}
.gomb {
  color: #8b6c57;
}
.gomb:hover {
  cursor: pointer;
  color: #b9850c;
}
.hover1 figure {
  position: relative;
  overflow: hidden;
}
.hover1 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover1 figure::before {
  -webkit-animation: shine 5s;
  animation: shine 5s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.header {
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
  .header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
</style>
